import React from 'react';

function App() {
  return (
    <div>
      <h1>Welcome to Chaiclub App!</h1>
      <p>This is your first React project Jonathan.</p>
      <h1>Mazal Tov!</h1>
    </div>
  );
}

export default App;